<form [formGroup]="userLoginForm" #userLogin="ngForm" (ngSubmit)="onSubmit()">
    <div *ngIf="!loggedUser" class="input-area align-top">
        <input type="text" name="loginId" formControlName="loginId" placeholder="email or ID" aria-label="email or ID" />
        <input type="password" name="passwd" formControlName="passwd" placeholder="Password" aria-label="Password" />
        <input type="submit" value="GO!" name="Login" class="btn-framed" />
    </div>
</form>
<div *ngIf="loggedUser" class="user-info">
    <span routerLink="/profile" class="profile"><i class="reicon icon-user-outline"></i>Profile</span>
    <span (click)="logout()" class="btn-framed">LOGOUT</span>
</div>
