<div *ngIf="!fullView" class="profile-wrapper">
    <div>ID: {{curentUser.user}}</div>
    <div>Nickname: {{curentUser.nick}}</div>
    <div>Color: #{{curentUser.color}}</div>
</div>


<div *ngIf="fullView" class="join-board-wrapper">
    <form [formGroup]="modUserForm" #userAdd="ngForm" (ngSubmit)="onSubmit()" class="container">
        <div class="row no-gutters">
            <div class="col-12 col-md-8 left-side">
                <div class="row">
                    <div class="col-12">
                        <label>Email:</label>
                        <input type="text" name="email" formControlName="email" placeholder="email" maxlength="50" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label>Password:</label>
                        <input type="password" name="pass1" formControlName="pass1" disabled />
                    </div>
                    <div class="col-6">
                        <label>Password(confirm):</label>
                        <input type="password" name="pass2" formControlName="pass2" disabled />
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <label>Nick Name:</label>
                        <input type="text" name="nickname" formControlName="nickname" placeholder="nickname" maxlength="24" />
                    </div>
                    <div class="col-7">
                        <label>Middle Name:</label>
                        <input type="text" name="middlename" formControlName="middlename" placeholder="middle name" maxlength="30" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label>First Name:</label>
                        <input type="text" name="firstname" formControlName="firstname" placeholder="first name" maxlength="30" />
                    </div>
                    <div class="col-6">
                        <label>Last Name:</label>
                        <input type="text" name="lastname" formControlName="lastname" placeholder="last name" maxlength="30" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <label>Title:</label>
                        <input type="text" name="title" formControlName="title" placeholder="title" maxlength="50" />
                    </div>
                    <div class="col-4">
                        <label>Dirthday:</label>
                        <input type="date" name="dob" formControlName="dob" placeholder="d.o.b." min="1920-01-01" max="2020-01-01" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label>Animal:</label>
                        <select name="avatar" formControlName="avatar" (change)="setAvatar($event)">
                            <option *ngFor="let animal of animals" [value]="animal">{{animal | uppercase}}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <label>Color:</label> 
                        <select name="color" formControlName="color" [ngStyle]="{'background-color':'#'+selectedColor}" (change)="setColor($event)">
                            <option *ngFor="let color of colors; let i = index" [value]="color.code" [ngStyle]="{'background-color':'#'+color.code,'color':'#'+color.code}">{{color.color}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label>Brief Description:</label>
                        <textarea cols="50" rows="5" name="description" formControlName="description" placeholder="description" maxlength="255"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label>Custom Image:</label>
                        <input id="photo" name="myimage" type="file" (change)="setPhoto($event)">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 right-side">
                <img [src]="'/assets/images/avatar/strawbag-'+selectedAnimal+'.png'" class="avatar-image">
                <hr>
                <img [src]="imageUrl" class="img-thumnail">
                <hr>
                <p class="pts">Pts:<span class="lg">{{curentUserDetail['points']}}</span> - Reward:<span class="lg">{{curentUserDetail['reward']}}</span></p>
            </div>
            
        </div>
        <div class="row">
            <div class="col-12 text-center bottom-button-wrapper">
                <input type="submit" name="submit" class="btn-submit" />
            </div>
        </div>
    </form>

</div>
<!-- firstname, lastname, email, middlename, nickname, title, dob, avatar, color, image, description -->