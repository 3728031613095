<div class="container">
    <header class="row">
        <div class="title-nav col-12 col-md-8">
            <app-profile class="profile" *ngIf="currentUserId>0 && profileOver" [fullView]="false"></app-profile>
            <img [src]="!errorImage?'https://javy.hardmouse.com/assets/images/users/upload/'+currentUserId+'/'+userImage:'https://javy.hardmouse.com/assets/images/users/happyface.png'" [routerLink]="'/blog/'+currentUserId"
                (mouseover)="profileOver=true" alt="User Image" (error)="errorImageCheck($event)"
                (mouseout)="profileOver=false" class="avatar white-frame-shade" [ngClass]="{'over':mouseOver}" />
            <h1 (mouseover)="mouseOver=true" (mouseout)="mouseOver=false">
                <a routerLink="/">
                    <span *ngIf="title">{{title}} Review</span>
                </a>
            </h1>
            <nav class="" style="color:#fff">
                <ul>
                    <li>
                        <a routerLink="/landing">Main</a>
                    </li>
                    <li>
                        <a routerLink="/contact">Contact</a>
                    </li>
                    <li>
                        <a routerLink="/about">About</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-12 col-md-4 text-right">
            <app-login></app-login>
        </div>
    </header>
</div>